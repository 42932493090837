<template>
    <div></div>
</template>

<script>
    export default {
        async beforeCreate() {
            if (this.$route.params.code) {
                let code      = this.$route.params.code;
                let response  = await this.func.ax("/php/getter.php", {
                    func: "get_redirection",
                    code: code,
                });
                location.href = this.func.add_http(response.url.trim())
            }
            else {
                let route;
                let logout_variable = "$logout";
                let route_variable  = "$route";
                if (this.func.get_localstoragedata(logout_variable) === "true") // If Logout
                {
                    localStorage.removeItem(btoa(logout_variable));
                    this.$user.set_default_user();
                }
                else // Redirect to route in localstorage
                {
                    route = this.func.get_localstoragedata(route_variable);
                    localStorage.removeItem(btoa(route_variable));
                }
                await this.$router.push(route ? route : this.$variables.v.navigation.default);
            }
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-redirect')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>